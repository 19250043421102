<template>
  <div class="home-section">
    <div
      style="z-index: 1"
      class="b-logo align-center d-flex flex-column"
    >
      <div class="b-logo d-flex align-center pt-10">
        <v-img
          alt="ЕДИНАЯ СРЕДА"
          class="shrink mr-2"
          src="~@/assets/logo-pic.svg"
          transition="scale-transition"
          width="200"
        />
        <v-img
          transition="scale-transition"
          alt="ЕДИНАЯ СРЕДА"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          src="~@/assets/logo-text.svg"
          width="200"
        />
      </div>
      <h3 class="light-style text-center">Единая среда — сервис учета объектов<br>городской инфраструктуры</h3>
      <v-btn
        large
        outlined
        rounded
        class="mt-5"
        color="white"
        :to="{name: 'login'}"
      >Авторизация
      </v-btn>
    </div>
    <div class="bg">
      <v-img
        style="background-position: center; background-size: cover"
        height="100%"
        width="100%"
        alt="ЕДИНАЯ СРЕДА"
        class="shrink mr-2"
        src="~@/assets/bg-3.svg"
      />
    </div>
    <div class="wrapper__video">
      <video playsinline=""
             poster="~@/assets/video/poppy.jpg"
             autoplay
             muted
             loop
             class="section__tizer-video"
             height="100%">
        <source src="~@/assets/video/poppy.webm"
                type="video/webm">
        <source src="~@/assets/video/poppy.mp4"
                type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>

<style scoped>
  .light-style {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    font-weight: 300;
  }
</style>
